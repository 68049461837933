import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useDoctorsList() {
    // Use toast
    const toast = useToast();

    const refDoctorListTable = ref(null);

    // Table Handlers
    let tableColumns = [
        { key: 'fullname', sortable: true, label: 'Nombre completo' },
        { key: 'cpm', sortable: true, label: 'CPM' },
        { key: 'position', sortable: true, label: 'Posición' },
        { key: 'medical_degree', sortable: true, label: 'Título médico' },
        { key: 'active', sortable: true, label: 'Estado' },
        { key: 'actions', label: 'Opciones' }
    ];

    const perPage = ref(10);
    const totalDoctors = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('id');
    const isSortDirDesc = ref(false);

    const dataMeta = computed(() => {
        const localItemsCount = refDoctorListTable.value ? refDoctorListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalDoctors.value,
        }
    });

    const refetchData = () => {
        refDoctorListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery], () => {
        refetchData();
    });

    const fetchDoctors = (ctx, callback) => {
        store
            .dispatch('app-doctor/fetchDoctors', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value
            })
            .then(response => {
                const { doctors, total } = response.data;
                callback(doctors);
                totalDoctors.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener la lista de doctores',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                });
            })
    };

    return {
        fetchDoctors,
        tableColumns,
        perPage,
        currentPage,
        totalDoctors,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refDoctorListTable,

        refetchData
    }
}